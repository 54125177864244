


























































































import Vue from "vue";
// import HelloWorld from './components/HelloWorld.vue';
export default Vue.extend({
    name: "App",
    data: () => ({
        isHome: false,
        drawer: false,
        group: null,
        navBar: [
            { title: "HOME", link: "/", icon: "mdi-home" },
            { title: "ABOUT US", link: "/about", icon: "mdi-account" },
            { title: "WIZARD", link: "/wizard", icon: "mdi-auto-fix" },
            { title: "TRAINING", link: "/training", icon: "mdi-alpha-t-box" },
            { title: "TESTING", link: "/testing", icon: "mdi-ab-testing" },
            { title: "SERVICES", link: "/services", icon: "mdi-face-agent" },
            { title: "CONTACT US", link: "/contact", icon: "mdi-account-box" },
        ],
    }),
    watch:{
        $route (to){
            this.isHome = to.name == 'Home' ? true : false;
        }
    } 
});
